interface Props {
  message: string;
}

export default function Alert({ message }: Props) {
  return (
    <div
      role="alert"
      className="relative mb-4 rounded-md border border-solid border-green-200 bg-green-100 p-4 text-black"
    >
      {message}
    </div>
  );
}
